@font-face {
    font-family: "neo-sans-regular";
    src:  url(media/NeoSans-regular.43981c0e.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "neo-sans-regular";
    src:  url(media/NeoSans-regular.43981c0e.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@lost --beta-direction rtl;
@lost gutter var(--grid-gutter);
a[href] {
    cursor: pointer !important;
}
body {
    padding: 0;
    margin: 0;
    font-family: 'neo-sans-regular';
}
body {
    background-color: #00c087;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100vh;
}
* {
    box-sizing: border-box;
}
img {
    max-width: 100%;
}
.container {
    width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
input, select, button, textarea {
    outline: 0;
    border: 0;
    font-family: 'neo-sans-regular';
}
.hide, .hidden {
    display: none;
}
.invisible {
    visibility: hidden;
}
.is-required {
    position: relative;
}
.is-required .required-sign {
    content: '*';
    display: block;
    float: right;
    color: #F44336;
    font-size: 20px;
    line-height: 1;
    margin-left: 5px;
    position: absolute;
    right: -15px;
}
.is-required .required-sign ~ label{
    /*margin-right: 15px;*/
}
.field-type--items_tree > .required-sign{
    top: 13px;
}
.is-required label.checkbox1--label {

}
.menu1--item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    z-index: 10;
}
.menu1--item li a {
    color: white;
    display: block;
    text-decoration: none;
    padding: 15px;
}
.menu1--item li {
    position: relative;
    cursor: pointer;
}
.menu1--item li:hover .submenu1 {
    visibility: visible;
    opacity: 1;
}
.submenu1 {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 500ms;
    transition: all 500ms;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    top: 100%;
    white-space: nowrap;
    background: #009860;
    box-shadow: 0 0 40px 0 rgba(62,62,62,.25);
    z-index: 10;
}
.submenu1 li {
    -webkit-transition: all 250ms;
    transition: all 250ms;
}
.submenu1 li:hover {
    background: #00c087;
}
.submenu1 li a {
    padding: 15px 25px;
}
@-ms-viewport {
    width: device-width;
}
html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.columns, .cols {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
}
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
}
.col.is-flex {
    display: -webkit-box;
    display: flex;
}
.col-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}
.col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}
.col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}
.col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}
.col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}
.col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}
.col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
}
.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}
.header {
    background: #009860;
}
.header .logo {
    width: 250px;
    display: block;
    padding: 15px 0;
}
.header .logo img {
    display: block;
}
.header .actions a {
    text-decoration: none;
    color: white;
    padding: 0 15px;
}
.header--content {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
}
.header .actions {
    margin: 0 -15px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
}
.btn {
    cursor: pointer;
    border: 2px solid transparent;
    background: #636466;
    color: #ffffff;
    font-size: 16px;
    padding: 10px 0;
    text-decoration: none;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: none;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.btn-primary:hover {
    background-color: #343537;
}
.btn-default {
    background: #636466;
}
.btn-default:hover {
    background: #343537;
}
.btn:disabled {
    background: #f0f0f0;
    color: #b7b7b7;
    cursor: not-allowed;
}
.media-popup-browse-btn {
    width: auto !important;
    width: initial !important;
    padding: 10px !important;
    margin: 15px 0 !important;
    font-size: 16px;
    border: solid 1px #343537;
    background: white;
    color: #343537;
}
.media-popup-browse-btn:hover {
    color: white;
}
.media-file--actions {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
}
.media-file--actions .btn-success {
    cursor: pointer;
    border: 2px solid transparent;
    background: #636466;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: none;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    margin: 5px;
    text-align: center;
}
.media-file--actions .btn-danger {
    cursor: pointer;
    border: 2px solid transparent;
    background: #636466;
    color: #ffffff;
    font-size: 14px;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: none;
    border-radius: 3px;
    box-shadow: none;
    -webkit-transition: 0.25s;
    transition: 0.25s;
    margin: 5px;
    text-align: center;
}
.media-file--actions .btn-success:hover, .media-file--actions .btn-danger:hover {
    background: #343537;
}
.is-loading {
    position: relative;
    /*text-indent: -90000px;*/
    pointer-events: none;
    color: transparent !important;
    background: #ddd;

}
.is-loading:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 12px;
    height: 12px;

    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -8px;
    border-radius: 50%;
    border: 2px solid transparent;
}
.is-loading:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    margin-left: -7px;
    margin-top: -8px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: transparent;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.alert, .errorMessage, .warningMessage, .successMessage {
    margin: 15px 0;
    padding: 15px 15px;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
}
.alert a{
    color: inherit;
}
.alert.alert--error,
.alert.alert-error,
.errorMessage,
.alert--error.warningMessage,
.alert--error.successMessage {
    border-color: #d74a3a;
    background: #eb4c3c;
    color: #fff
}
.alert.alert--warning,
.alert.alert-warning,
.alert--warning.errorMessage,
.warningMessage,
.alert--warning.successMessage {
    border-color: #d7a100;
    background: #ebb100;
    color: #fff
}
.alert.alert--info,
.alert.alert-info,
.alert--info.errorMessage,
.alert--info.warningMessage,
.alert--info.successMessage {
    border-color: #34c0eb;
    background: #34acd7;
    color: #fff
}
.alert.alert--success,
.alert.alert-success,
.alert--success.errorMessage,
.alert--success.warningMessage,
.successMessage {
    border-color: #9b9b9b;
    background: #676767;
    color: #fff
}
.alert.alert--default,
.alert.alert-default,
.alert--default.errorMessage,
.alert--default.warningMessage,
.defaultMessage {
    border-color: #9b9b9b;
    background: #676767;
    color: #fff
}
.alert--close{
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: solid 1px white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: white;
}
.alert--close:before {
    content: 'x';
    position: absolute;
    left: 53%;
    top: 38%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -webkit-transition: all 250ms;
    transition: all 250ms;
    color: black;
}
.alert--close:hover:before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    top: 0;
    left: 48%;
    box-shadow: 1.5px 2.598px 7px 0 rgba(0,0,0,.05);
}
.table {
    width: 100%;
}
.table1--wrapper {
    padding: 30px 0;
}
.table1 {
    border: 1px solid #f0f0f0;
}
.table1--head .table1--cell {
    font-size: 20px;
    font-weight: bold;
}
.table1--body {

}
.table1--row {
    display: -webkit-box;
    display: flex;
}
.table1--cell {
    -webkit-box-flex: 1;
            flex: 1;
    padding: 15px 22px;
    border-top: 1px solid #f0f0f0;
    font-weight: normal;
    color: white;
}
.table1--cell.no-border {
    border-top: none;
}
a.table1--row {
    color: black;
}
.table1--cell a {
    color: white;
    text-decoration: none;
}
.breadcrumb {
    display: -webkit-box;
    display: flex;
    padding: 30px 0;
}
.breadcrumb--item a {
    text-decoration: none;
    color: white;
}
.breadcrumb--item {
    color: white;
}
.breadcrumb--item + .breadcrumb--item:before {
    content: '>';
    display: inline-block;
    padding: 0 15px;
    color: white;
}
.field-wrapper {
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
}
.field-wrapper label {
    font-size: 13px;
    color: #8c9397;
}
.form-group {
    width: 100%;
    position: relative;
    margin: 5px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
}
.form-control, .input {
    width: 100%;
    display: inline-block;
    border: 1px solid #ddd;
    font-size: 14px;
    font-family: inherit;
    padding: 15px;
    outline: none;
    -webkit-transition: all .25s;
    transition: all .25s;
    color: #999;
    position: relative;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
select.form-control {
    height: 100%;
}
label~.form-control, label~.input, label~.select2-container {
    margin-top: 10px;
}
.form1--title {
    text-align: center;
    color: white;
}
.matrix-control.single-view .matrix-body {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.matrix-control.single-view .matrix-row {
    width: calc(100%/3);
}
.matrix-control.single-view {
    margin-top: 10px;
}
.matrix-control.single-view {
    border: none;
}
.help-block, label.error {
    padding: 8px;
    clear: both;
    font-size: 12px;
    display: block;
}
.has-error .form-control {
    border-color: red;
}
/*.has-error *,*/
.has-error .help-block, .has-error a {
    color: red;
}
label.error {
    padding: 8px;
    clear: both;
    font-size: 12px;
    color: red;
}
.form-control.error, input.error, select.error {
    border-color: red;
}
.is-flex .field-wrapper{
    height: 100%;
}
[id*="mediaPopUpModal"].modal.modal-xxl .modal-dialog{
    max-width: 90%;
    width: 800px;
}
.modal-footer .btn {
    margin: 0 15px;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
    background-color: #fff;
    box-shadow: 1.5px 2.598px 7px 0 rgba(0,0,0,.15);
    border-radius: 4px;
    border-color: gray;
    position: absolute;
    z-index: 9999;
}
.pagination1 .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
}
.pagination-container {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: 20px;
}
ul.pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    border: 1px solid #009860;
    border-radius: 4px;
    overflow: hidden;
}
ul.pagination a {
    display: -webkit-box;
    display: flex;
    padding: 10px;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    font-size: 15px;
    border-right: 1px solid #009860;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    color: white;
    text-decoration: none;
}
ul.pagination a.active {
    background-color: #009860;
    color: #fff;
}
ul.pagination a.disabled {
    color: #959595;
}
ul.pagination a.disabled:hover {
    cursor: auto;
}
ul.pagination a:not(.disabled):hover {
    background-color: #009860;
    color: #fff;
    cursor: pointer;
}
ul.pagination li:last-child a {
    border-right: 0;
}
.dataTables_info {
    color: white;
    padding: 5px 15px;
}
.panel1 {
    background-color: #fff;
    padding: 45px!important;
    box-shadow: 1.5px 2.598px 7px 0 rgba(0,0,0,.05);
    border-radius: 4px;
    margin-bottom: 20px;
}
.panel1--head {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 30px;
}
.panel1--title {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin: 0;
}
.form1 {
    padding: 30px 0;
}
::-webkit-input-placeholder { /* Edge */
    color: #d0d0d0;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #d0d0d0;
}
::-moz-placeholder {
    color: #d0d0d0;
}
::-ms-input-placeholder {
    color: #d0d0d0;
}
::placeholder {
    color: #d0d0d0;
}
.item1 {
    color: white;
}
.item1--title {
    font-size: 18px;
}
/*Leave responsive.css import at last*/
